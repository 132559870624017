import React, { FC, useState } from 'react'
import { Box, Card, CardContent, Container, TextField, Typography } from '@mui/material'
import Logo from '../components/Logo'
import axiosInstance from '../lib/axios'
import { useSnackbar } from 'notistack'
import { useParams, useSearchParams } from 'react-router-dom'
import { Verification } from '../components/Verification'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { TestListing } from '../components/TestListing'

export const Login: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  let { token } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const [results, setResults] = useState<{
    result_de: any | null
    result_en: any | null
    result_japan: any | null
  }>({ result_de: null, result_en: null, result_japan: null })

  const onCheckCode = (code: string) => {
    setIsLoading(true)
    axiosInstance
      .post(
        'authentication/checkTestTokens',
        { token: token.split('language=')[0], password: code },
        {}
      )
      .then((response) => {
        setResults(response.data)
      })
      .catch((error) => {
        enqueueSnackbar(
          error.message ||
            'Leider ist ein Fehler aufgetrete. Bitte probiere es später noch einmal.',
          { variant: 'error' }
        )
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container maxWidth='sm' sx={{ py: '80px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <a color='primary' href='https://mein-schnelltest.com'>
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </a>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              {results.result_de === null &&
              results.result_en === null &&
              results.result_japan === null ? (
                <Verification
                  isLoading={isLoading}
                  onCheckCode={onCheckCode}
                  language={
                    token.split('language=').length > 1 ? token.split('language=')[1] : 'DE'
                  }
                />
              ) : (
                <TestListing
                  result_de={results.result_de}
                  result_en={results.result_en}
                  result_japan={results.result_japan}
                />
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
      <LoadingSpinner isVisible={isLoading} />
    </>
  )
}
